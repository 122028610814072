import { FC, memo } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  AvailableTag,
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";
import TextTitle from "apps/website/components/base/Text/TextTitle/TextTitle";

import { Columns, columnSpanObjectMap } from "./ImageTextListItemColumn.map";

export interface IImageTextItem {
  image: IImageProps;
  title: string;
  titleDisplay?: Display;
  body?: string;
  cta?: ILink;
}

export interface IImageTextListItemColumn {
  item: IImageTextItem;
  columns?: Columns;
  itemTitleTag: AvailableTag;
}

const ImageTextListItemColumn: FC<IImageTextListItemColumn> = ({
  item,
  itemTitleTag = "h3",
  columns = "[2,4]",
}) => (
  <Column component="ImageTextListItemColumn" tag="li" key={ item.title } spans={ columnSpanObjectMap[columns] } align="center">
    <div className="mx-8">
      { item.image?.src && (
        <Image image={ item.image } alt={ item.title } />
      ) }
    </div>
    <Spacer size="md" />
    { (item.titleDisplay === "title") ? (
      <TextTitle tag={ itemTitleTag } size={ 2 } align="center" >{ item.title }</TextTitle>
    ) : (
      <Text tag={ itemTitleTag } display={ item.titleDisplay || "title" } size={ legacySizeCollectionMap.titleSm } align="center" className="flex-1" whitespace={ { lg: "preWrap" } }>{ item.title }</Text>
    ) }

    { item.body && (
      <>
        <Spacer size="md" />
        <Text align="center" size={ legacySizeCollectionMap.base } whitespace={ { lg: "preWrap" } } className="flex-auto">{ item.body }</Text>
      </>
    ) }
    { !!item.cta && (
      <>
        <Spacer size="lg" />
        <Button to={ item.cta.to } design={ item.cta.design } color={ item.cta.color }>{ item.cta.name }</Button>
      </>
    ) }
  </Column>
);

export default memo(ImageTextListItemColumn);
