import { FC, memo, useCallback } from "react";

import {
  AvailableTag as TitleTag,
} from "apps/website/components/base/Text/Text.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import { AvailableTag } from "apps/website/components/base/List/List.map";

import ImageTextListItemColumn, {
  IImageTextItem,
} from "./ImageTextListItem/ImageTextListItemColumn";
import { Columns } from "./ImageTextListItem/ImageTextListItemColumn.map";
import ImageTextListItemRow from "./ImageTextListItem/ImageTextListItemRow";

export type Flow = "column" | "row";

export interface IImageTextList {
  items: IImageTextItem[];
  itemTitleTag?: TitleTag;
  listType?: AvailableTag;
  flow?: Flow;
  columns?: Columns;
}

const ImageTextList: FC<IImageTextList> = ({
  items = [],
  itemTitleTag = "h3",
  listType = "ul",
  flow = "row",
  columns,
}) => {

  const makeListItem = useCallback((item: IImageTextItem, number: number, itemFlow: Flow): IImageTextItem => ({
    ...item,
    title: listType === "ol" ? `${number}. ${item.title}` : item.title,
    titleDisplay: listType === "ol" || itemFlow === "column" ? "title" : "subtitle",
  }), [ listType ]);

  return (
    <Grid tag={listType} component="ImageTextList">
      <>
        { flow === "row" && (
          <>
            { items.map((item, index) => (
              <ImageTextListItemColumn
                key={item.title}
                item={makeListItem(item, index + 1, "row")}
                itemTitleTag={itemTitleTag}
                columns={columns}
              />
            )) }
          </>
        ) }
        { flow === "column" && (
          <>
            { items.map((item, index) => (
              <ImageTextListItemRow
                key={item.title}
                item={makeListItem(item, index + 1, "column")}
                itemTitleTag={itemTitleTag}
                direction={index % 2 === 0 ? "default" : "reverse"}
              />
            )) }
          </>
        ) }
      </>
    </Grid>
  );
};

export default memo(ImageTextList);
