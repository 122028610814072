import clsx from "clsx";
import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { defaultJustifyClassMap, Justify } from "apps/website/maps/Flex.map";

import {
  TDirection,
  TSize,
  sizeClassMap,
  directionClassMap,
} from "./SectionArrow.map";

export interface ISectionArrowProps {
  direction?: TDirection;
  backgroundTheme?: Theme;
  arrowTheme?: Theme;
  size?: TSize;
  justify?: Justify;
  className?: string;
}
const SectionArrow: FC<ISectionArrowProps> = ({ direction = "DOWN", backgroundTheme = "default", arrowTheme = "brand", size = "default", justify = "center", className }) => (
  <div data-component={ SectionArrow.name } className={ `w-full flex ${defaultJustifyClassMap[justify]} ${themeRootClassMap[backgroundTheme]}` }>
    <div
      className={
        clsx(
          className,
          sizeClassMap[size],
          directionClassMap[direction],
          `border-transparent border-b-${arrowTheme}`,
          `${direction === "UP" ? "" : "rotate-[180deg]"}`,
        )
      }
    />
  </div>
);

export default SectionArrow;
