import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";

import Text from "../Text";
import {
  Align,
  AvailableTag,
  BreakpointWhiteSpace,
  Color,
  Whitespace,
} from "../Text.map";

import {
  controlDisplayMap,
  controlSizeMap,
  TControlSize,
} from "./TextControl.map";

export interface IControlProps extends WithDataAttr {
  id?: string;
  tag?: AvailableTag;
  size: TControlSize
  align?: Align;
  color?: Color;
  children: ReactNode;
  whitespace?: Whitespace | BreakpointWhiteSpace;
}

const TextControl: FC<IControlProps> = ({ id, size, align, color, tag = "h2", children, "data-testid": testId, whitespace = "default" }) => (
  <Text
    id={ id }
    data-testid={ testId }
    size={ controlSizeMap[size] }
    display={ controlDisplayMap[size] }
    align={ align }
    tag={ tag }
    color={ color }
    whitespace={ whitespace }
  >
    { children }
  </Text>
);

export default memo(TextControl);
