import { FC } from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";

import Image, { IImageProps } from "../../base/Image/Image";
import Grid from "../../layout/Grid/Grid";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";
import Icon from "../../base/Icon/Icon";
import { ITagProps } from "../../base/Tag/Tag";
import TextControl from "../../base/Text/TextControl/TextControl";

interface IKatkinVersesCompetitorItemFooter {
  title: string;
  body: string;
}
export interface IKatkinVersesCompetitorItem {
  title: string;
  image: IImageProps;
  tag: ITagProps;
  footer: IKatkinVersesCompetitorItemFooter;
}

export interface IKatkinVersesCompetitorProps {
  items: [IKatkinVersesCompetitorItem, IKatkinVersesCompetitorItem];
}

const KatkinVersesCompetitor: FC<IKatkinVersesCompetitorProps> = (props) => (
  <Grid component={ KatkinVersesCompetitor.name } gap="lg" className="relative">
    <>
      { props.items.map((item) => (
        <Column key={ item.title } spans={ 6 } align="center" data-testid="KatkinVersesCompetitorItem">
          <TextSubtitle tag="h3" size={ 2 } align="center" data-testid="KatkinVersesCompetitorItemTitle">{ item.title }</TextSubtitle>
          <Spacer size="gap-default" />
          <div className="max-w-[160px] relative">
            <Image image={ item.image } alt={ item.title } data-testid="KatkinVersesCompetitorItemImage" />
            <div className={ `p-1 absolute top-0 right-0 ${themeRootClassMap[item.tag.theme || "default"]}` } data-theme={ item.tag.theme } >
              <TextControl tag="div" size={ 3 }>{ item.tag.text }</TextControl>
            </div>
          </div>
          <TextSubtitle tag="h4" size={ 3 } align="center" data-testid="KatkinVersesCompetitorItemFooterTitle">{ item.footer.title }</TextSubtitle>
          <TextBody tag="div" align="center" size={ 2 } data-testid="KatkinVersesCompetitorItemFooterBody">{ item.footer.body }</TextBody>
        </Column>
      )) }
      <Icon size="small" icon="equals" className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" />
    </>
  </Grid>
);

export default KatkinVersesCompetitor;
