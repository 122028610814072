import { BreakpointSize, Display, sizeCollectionMap } from "../Text.map";

export const controlSizeMap: Record<number, BreakpointSize> = {
  1: sizeCollectionMap[3],
  2: sizeCollectionMap[6],
  3: sizeCollectionMap[8],
  4: sizeCollectionMap[8],
};

export type TControlSize = keyof typeof controlSizeMap;

export const controlDisplayMap: Record<TControlSize, Display> = {
  1: "subtitle",
  2: "subtitle",
  3: "title",
  4: "default",
};
